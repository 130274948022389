import React from "react";
import cx from "classnames";
import * as Globals from "@elevio/kb-kit/lib/utils/globals";

type Props = {
  className?: string;
};

export const Logo: React.FC<Props> = ({ children, className, ...props }) => (
  <a href="https://www.torranceca.gov/home" className={cx("block", className)}>
    <h1 className="text-3xl">
      <img src={Globals.getAssetURL("logo.png")} className="w-32" />
    </h1>
  </a>
);
