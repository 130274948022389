import { Config } from "@elevio/kb-kit/lib/Config";
import Loader from "./components/Loader";
import ArticlePage from "./pages/article";
import CategoryPage from "./pages/category";
import HomePage from "./pages/home";
import SearchPage from "./pages/search";
import Error404Page from "./pages/error-404";
// import Error403Page from './pages/error-403';
import ErrorPage from "./pages/error";
import IFramePage from "./pages/iframe";

const config: Config = {
  pages: {
    article: {
      component: ArticlePage,
    },
    home: {
      component: HomePage,
    },
    category: {
      component: CategoryPage,
    },
    search: {
      component: SearchPage,
    },
    error404: {
      component: Error404Page,
    },
    // error403: {
    //   component: Error403Page,
    // },
    error: {
      component: ErrorPage,
    },
  },
  pageLoadingIndicator: Loader,
  extraPages: [
    {
      path: "new-submission",
      component: IFramePage,
    },
  ],
};

export default config;
