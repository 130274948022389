import React from "react";
import cx from "classnames";
import { ClickableCard } from "@elevio/styles/lib/components/cards";
import Icon from "@elevio/styles/lib/components/icons";
import { MediumHeading } from "@elevio/styles/lib/components/text";

import { addClassName } from "@elevio/styles/lib/utils/classes";

import useCategory from "@elevio/kb-kit/lib/hooks/useCategory";
import * as Category from "@elevio/kb-kit/lib/components/category";

type Props = {
  categoryId?: string;
  className?: string;
};

const CategoryCard: React.FC<Props> = ({ categoryId, className }) => {
  const category = useCategory(categoryId);

  if (!category) return null;

  return (
    <Category.Link className="flex flex-1">
      <ClickableCard
        className={cx(addClassName("category-card"), "text-center", className)}
      >
        <div className="p-6" data-testid="category-link">
          <Icon icon="help" size="large" className="mx-auto mb-6" />

          <MediumHeading className="mb-2">{category.title}</MediumHeading>
        </div>
      </ClickableCard>
    </Category.Link>
  );
};

export default CategoryCard;
