import * as React from "react";
import { BodyInverse, MediumHeading } from "@elevio/styles/lib/components/text";
import { Card } from "@elevio/styles/lib/components/cards";
import * as SearchResults from "@elevio/kb-kit/lib/components/searchResults";

import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { MainCentered } from "../components/layouts/Main";
import SearchResult from "../components/SearchResult";
import SearchMore from "../components/SearchMore";
import SearchInput from "../components/SearchInput";

const Page = () => (
  <PageLayout
    header={
      <Header hideSearch>
        <SearchResults.IsNotEmpty>
          <BodyInverse className="mb-4">
            We found <SearchResults.TotalCount /> articles for{" "}
            <strong>
              "<SearchResults.SearchTerm />"
            </strong>
          </BodyInverse>
        </SearchResults.IsNotEmpty>
        <SearchInput placeholder="Search our customer portal..." />
      </Header>
    }
    footer={<Footer />}
  >
    <MainCentered>
      <SearchResults.LoopResults>
        <SearchResult className="mb-6 block" />
      </SearchResults.LoopResults>

      <SearchResults.IsEmpty>
        <Card className="py-16 text-center">
          <MediumHeading className="mb-4">
            Sorry we couldn’t find anything for “<SearchResults.SearchTerm />”
          </MediumHeading>
          <p>
            Try another search or <a href="#">Browse by category</a>
          </p>
        </Card>
      </SearchResults.IsEmpty>

      <SearchMore className="block mx-auto " />
    </MainCentered>
  </PageLayout>
);

export default Page;
