import * as React from "react";
import { MainHeading, Body } from "@elevio/styles/lib/components/text";

import * as Category from "@elevio/kb-kit/lib/components/category";
import * as Article from "@elevio/kb-kit/lib/components/article";
import * as Articles from "@elevio/kb-kit/lib/components/articles";

import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import WithAsideLayout, {
  Aside,
  Main,
  MainHeader,
  MainBody,
} from "../components/layouts/WithAside";
import SearchInput from "../components/SearchInput";

import Breadcrumb from "../components/PageBreadcrumbs";
import CategoryList from "../components/CategoryList";

const Page = () => (
  <PageLayout header={<Header />} footer={<Footer />}>
    <WithAsideLayout>
      <Aside>
        <SearchInput placeholder="Search" className="mb-12 hidden md:block" />
        <CategoryList />
      </Aside>

      <Main>
        <MainHeader>
          <Breadcrumb className="mb-6" />

          <MainHeading className="mb-4">
            <Category.Title />
          </MainHeading>
          <Body />
        </MainHeader>

        <MainBody>
          <Articles.Loop>
            <div className="px-6 mb-6 block">
              <Article.Link className="mb-2">
                <Article.Title />
              </Article.Link>
              <div className="text-grey-light text-sm">
                <Article.Summary />
              </div>
            </div>
          </Articles.Loop>
        </MainBody>
      </Main>
    </WithAsideLayout>
  </PageLayout>
);

export default Page;
